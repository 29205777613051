body{
  font-family: 'Montserrat'
}

html,body{
  width:100%;
  margin:0;
  padding:0;
}

.wrapper{
  width: 100%;
}

.background-wrapper{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.layout-wrapper{
  display: grid;
  grid-template-rows: 60vw auto;
  height: fit-content;
  width: 100%;
}

.layout{
  grid-row-start: 2;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 0px repeat(2, auto) auto;
  grid-gap: 2px;
}




