.contact-wrapper{
    display: grid;
    grid-template-columns: repeat(3 ,auto);
    justify-content: center; margin-bottom: 50px;
}

.contact-btn-wrapper{
  display: grid;
  grid-template-rows: 120px auto;
  margin: 10px;
  color: rgb(242, 102, 95);
  align-items: center;
  justify-items: center;
}

.contact-icon{
    margin: 3vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 80px;
    justify-self: center;
    align-content: center; 
}

.contact-url{
  font-weight: 500;
}

.click-response {
  content: '';
  background-color: white;
  position: relative;
  z-index: -1;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  background: rgba(255,255,255, 0.7);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transform: scale(0);
  
    
  }
 
  @keyframes circleGrow {
    0% {
      transform: scale(0);
      opacity: 0;
    } 
    20% {
      opacity: 1;
    }
    100% {
      transform: scale(3);
      opacity: 0;
    }
  }


@media only screen and (max-width: 818px) {

  .contact-wrapper{
    grid-template-rows: repeat(3 ,auto);
        grid-template-columns: auto;
  }
}

