
.center-con {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 20px;
    transition: opacity 1s;
    height: 780px;
}


.round {
    border: 2px solid #F2665F;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: rgba(127, 127, 127, 0.2); 
}

#cta{
    width:100%; 
    cursor: pointer; 
    position: absolute;
}

#cta .arrow{left: 23%;}
.arrow {position: absolute; bottom: 0;  margin-left:0px; width: 12px; height: 12px; background-size: contain; top:15px;}
.second{margin-left: 10px;}
.next {
	background-image: url('/public/SVG/arrow.svg');
}

@keyframes bounceAlpha {
  0% {opacity: 1; transform: translateX(0px) scale(1);}
  25%{opacity: 0; transform:translateX(10px) scale(0.9);}
  26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
  55% {opacity: 1; transform: translateX(0px) scale(1);}
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}

.arrow.primera.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-delay:0.2s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}

.round:hover .arrow{
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}
.round:hover .arrow.first{
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-delay:0.2s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}


