.about-wrapper{
    display: grid;
    width: 100%;
    align-content: center;
    justify-content: center;
}

.about-me-text{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    color: #c2d8f1;
    text-align: center;
    padding: 0px 50px;
    margin: 50px 0px;
    max-width: 650PX;
    min-width: 200px;
    font-weight: 300;
}

blockquote{
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 400;
}