.section-header{
    display: flex;
    width: 100%;
    height: 4rem; 
}



.section-header-line{
    width: 96%;
    justify-self: center;
    align-self: center;
}

.section-header-line.red{
    border: 2px solid #F2665F;
}

.section-header-line.yellow{
    border: 2px solid #F7D789;
}

.section-header-line.left{
    margin-left: 10px;
}

.section-header-line.right{
    margin-right: 10px;
}