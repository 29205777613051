.menubar{
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height:50px;
    align-content: space-evenly;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(46,0,0,1) 2%, rgba(18,28,37,1) 50%, rgba(0,0,0,1) 100%);
    z-index: 100;
}

.site-title{
    padding: 5px 10px 5px 10px;
}


.menu-wrapper{
    display: grid;
    justify-self: end;
    align-items: center;
    padding-right: 10px;
}


.menu-wrapper > div {
    height: fit-content;
    padding: 5px 10px;
    cursor: pointer
}

@media only screen and (max-width: 600px) {
    .menubar{ 
      font-size: 0.7em;
  }
  .site-title{
      font-size: 0.7em;
  }
  .menu-wrapper > div{
      padding: 5px 8px;
  }
  .menu-wrapper{
      padding-right: 4px;
  }
}

