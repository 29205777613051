.work-wrapper{
    display: grid;
    overflow-x: hidden;
    grid-template-columns: minmax(60px, 1fr) minmax(100px, 600px) minmax(60px, 1fr);
    width: 100%;
    margin: 50px 0px;
}

.projects-wrapper{
    grid-column-start: 2;
    display: grid;
    align-self: center;
    justify-self: center;
    width: 100%;
    height: 100%;
}

.carousel-btn{
    z-index: 10;
    display: block;
    grid-row-start: 1;
}

.carousel-btn-left{
    grid-column-start: 1;
    justify-self: end; 
}

.carousel-btn-right{
    grid-column-start: 3;
    justify-self: start;
}

.project-wrapper{
    grid-row-start: 1;
    grid-column-start: 2;
    display: grid;
    border: 2px solid #F2665F;
    padding: 10px;
    background: #111a22;
}



@media only screen and (max-width: 600px) {
    .work-wrapper{
        grid-template-columns: 100%;
        grid-column-start: 1;
        align-self:center;
    }
    .carousel-btn-left{
        grid-column-start: 1;
        justify-self: start;
    }
    .carousel-btn-right{
        grid-column-start: 1;
        justify-self: end;
    }

    .project-wrapper{
        grid-row-start: 1;
        grid-column-start: 1;
    }
}
