



.project-inner-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 300px repeat(5, auto) minmax(0, 1fr) auto;
    border: 2px solid #F7D789;
    grid-gap: 5px;
}

.project-image{
    background-size: cover;
    height: 100%;
    grid-column-start: 1;
    grid-row-start: 1;
}

.inset-shadow{
    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;
}

.project-title{
    grid-column-start: 1;
    grid-row-start: 2;
    justify-self: center;
    align-self: center;
    color: #c2d8f1;
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding: 10px;
}

.project-subtitle{
    grid-column-start: 1;
    grid-row-start: 3;
    justify-self: center;
    align-self: center;
    color: #c2d8f1;
    margin-block-start: 0px;
    margin-block-end: 0px;
    max-width: 250px;
    font-weight: 100;
    text-align: center;
}


.project-role{
    grid-column-start: 1;
    grid-row-start: 4;
    justify-self: center;
    align-self: center;
    color: #c2d8f1;
    max-width: 250px;
    text-align: center;
}

.project-stack{
    grid-column-start: 1;
    grid-row-start: 5;
    color: #c2d8f1;
    text-align: center;
    padding: 10px;
    font-weight:400;
    font-style: italic;
}

.project-description{
    grid-column-start: 1;
    grid-row-start: 6;
    color: #c2d8f1;
    text-align: center;
    padding: 10px;
    font-weight:300;
}

.project-link{
    align-self: center;
    justify-self: center;
    color: #c2d8f1;
    font-weight: bold;
    margin-bottom: 10px;
}

